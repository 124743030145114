import styled from "@emotion/styled"
import tw from "twin.macro"

import { Label, P } from "../../../Styled/Text"
import { TextButton } from "../../../Styled/Button"

export const StyledP = tw(P)`w-full tracking-normal text-grey-darker`
export const StyledLabel = tw(Label)`mb-0-8`
export const Empty = tw(P)`text-center p-0-6 w-full`
export const Addresses = tw.div`flex flex-wrap -m-0-6 mb-4`
export const Address = tw.li`block w-full md:w-1/2 p-0-6`
export const Content = styled.div`
  ${tw`flex flex-col justify-between p-1-6 pb-0-9 border text-left h-full transition-all duration-200`}
  ${props => (props.selected ? tw`border-grey-darkest` : tw`border-grey`)}
`
export const Details = tw.div`pb-4-8`
export const Links = tw.div`flex items-end`
export const StyledTextButton = tw(TextButton)`flex items-center justify-start mr-2-4 hover:no-underline hover:text-grey-darkest`
